import { NavLink } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";

function Footer() {
    return (
        <>
            <footer className="site-footer footer-dark">
                {/* FOOTER BLOCKES START */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* ABOUT COMPANY */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="widget widget_about">
                                    <div className="logo-footer clearfix p-b15">
                                        <NavLink to="/index" className="sx-text-primary"><ItodoImage src="images/Logo.png" alt="#" /></NavLink>
                                    </div>
                                    <p>
                                        At World Wide Dispatch Management, we specialize in streamlining operations, improving efficiency, and delivering exceptional service to meet all your dispatch needs.
                                    </p>
                                    <div className="widget_about-call-section">
                                        <div className="sx-f-call-icon"><i className="flaticon-telephone" /></div>
                                        <div className="sx-f-call-section">
                                            <span>Contact us 24/7</span>
                                            <a href="tel:+1 (347) 443-8106">+1 (347) 443-8106</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* EXPLORE LINKS */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title">Our Services</h4>
                                    <ul>
                                        <li><NavLink to="/services/Taxi-Dispatch-Outsourcing-Solutions">Taxi Dispatch Outsourcing Solutions</NavLink></li>
                                        <li><NavLink to="/services/Limo-Dispatch-Outsourcing-Solutions">Limo Dispatch Outsourcing Solutions</NavLink></li>
                                        <li><NavLink to="/services/Non-Emergency-Medical-Transportation-(NEMT)-Dispatch-Outsourcing">Non-Emergency Medical Transportation (NEMT) Dispatch Outsourcing Solutions</NavLink></li>
                                        <li><NavLink to="/services/Truck-Dispatch-Services">Truck Dispatch Services</NavLink></li>
                                        <li><NavLink to="/services/Website-Development-Services">Website Development Services</NavLink></li>
                                        <li><NavLink to="/services/Social-Media-Marketing-and-Account-Management">Social Media Marketing and Account Management</NavLink></li>
                                        <li><NavLink to="/services/Voice-Service-for-Inbound-and-Outbound-Call-Center-Services">Voice Service for Inbound and Outbound Call Center Services</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            {/* USEFUL LINK */}
                            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title">Useful Links</h4>
                                    <ul>
                                        <li><NavLink to="/about-us">About Us</NavLink></li>
                                        <li><NavLink to="/why-choose-us">Why Choose Us</NavLink></li>
                                        <li><NavLink to="/term-and-condition">Terms &amp; Condition</NavLink></li>
                                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            {/* SOLUTIONS */}
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-12 col footer-col-3">
                                <div className="widget widget_info">
                                    <h4 className="sx-f-title">Solutions</h4>
                                    <ul>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-phone" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <a href="tel:+1 (347) 443-8106">+1 (347) 443-8106</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-email" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <p>info@wwdispatchm.com</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-maps-and-flags" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <p>39 weatherall ave, Cambridge, ON, Canada, Ontario</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sx-f-bottom-section">
                    <div className="sx-f-social">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=61555253836713">
                                    <i className="feather-facebook" />{" "}
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/world-wide-dispatch-management/mycompany/">
                                    <i className="feather-linkedin" />{" "}
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/wwdispatchm/">
                                    <i className="feather-instagram" />{" "}
                                </a>
                            </li>
                            <li>
                                <a href="https://x.com/wwdispatchm">
                                    <i className="feather-twitter" />{" "}
                                </a>
                            </li>
                            <li>
                                <a href="https://www.pinterest.com/wwdispatchm/">
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFFklSM2f8zAKlIZGNG_mqm4V136eG7rt-lg&s" alt="pinterest" width={'20px'} height={'18px'} />
                                    {/* <i className="feather-pinterest" />{" "} */}
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCIQw6KivHxUkMGKgQgcOCVQ">
                                    <i className="feather-youtube" />{" "}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="sx-f-copy">
                        © 2023 by <NavLink to="">wwdispatchm.</NavLink> All rights reserved.
                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
            </footer>

        </>
    )
}

export default Footer;
